<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-05-16 09:31:31
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-05-27 11:55:29
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SummaryStudentStatus\statisticsData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="statistics-data">
        <div class="statistics-item">
            <span class="title">总计</span>
        </div>
        <div class="statistics-item">
            <img :src="totalUrl" alt="">
            <span class="title">应到人数</span>
            <span class="num">{{ statisticsData.totalNum }}</span>
        </div>
        <div class="statistics-item">
            <img :src="actualUrl" alt="">
            <span class="title">实到人数</span>
            <span class="num">{{ statisticsData.actualNum }}</span>
        </div>
        <div class="statistics-item">
            <img :src="attendedUrl" alt="">
            <span class="title">未到人数</span>
            <span class="num">{{ statisticsData.attendedNum }}</span>
        </div>
        <div class="statistics-item">
            <img :src="attendedUrl" alt="">
            <span class="title">请假人数</span>
            <span class="num">{{ statisticsData.leaveNum }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "statisticsData",
    props: {
        statisticsData: {
            type: Object
        }
    },
    computed: {
        totalUrl() {
             return require('@/assets/images/student-status-total.png')
        },
        actualUrl() {
             return require('@/assets/images/student-status-actual.png')
        },
        attendedUrl() {
             return require('@/assets/images/student-status-attended.png')
        }
    }
}
</script>

<style scoped lang="scss">
.statistics-data {
    display: flex;
    background-color: #fff;
    padding: 10px 13px;

   .statistics-item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;
        margin-right: 30px;
        padding-right: 30px;
        white-space: normal;

        &:first-child {
            color: #737373;
            font-weight: bold;
            border-right: 1px solid #D7D9DB;
        }

        .title {
            white-space: nowrap;
        }

        img {
            margin-right: 8px;
        }

        .num {
            margin-left: 10px;
            font-weight: bold;
            color: #7A8A99;
        }
    }
}
</style>
