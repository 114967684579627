<template>
    <div class="summary-student-status-info content-bg">
        <global-page-back
            :detailPageTitle="`${studentInfo.studentName}的历史出勤`"
            @handlerGlobalType="back"
        >
        </global-page-back>

        <expand-filter
            :close-width="960"
            :formData="formData"
            marginTop="10px"
            @clickBtn="clickBtn"
            @changeBtnFormType="changeBtnFormType"
            @dataChange="dataChange"
        >
        </expand-filter>

        <table-data
            v-loading="loadingTable"
            ref="table"
            :config="table_config"
            :tableData="table_data"
        >   
            <template v-slot:week="slotData">
                <div>{{getWeek(slotData.data.attendanceDate)}}</div>

            </template>
            <template v-slot:status="slotData">
                <el-link 
                    :type="getAttendancesStatusBycode(slotData.data.status)['type']"
                    :underline="false"
                    :style="{
                        cursor:'auto'
                    }"
                >
                    {{getAttendancesStatusBycode(slotData.data.status)['name']}}
                </el-link>

            </template>
        </table-data>
    </div>
</template>

<script>
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import ExpandFilter from "./Sub/ExpandFilter";
import TableData from "@/components/scrollWrapper/Sub/TableData"
export default {
    components: {
        GlobalPageBack,
        ExpandFilter,
        TableData
    },
    data() {
        return {
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "datePick",
                        label: "",
                        value: "",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        key: "dateArr",
                        clearable:false,
                        pickerOptions: {
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                                // return true
                            },
                        },
                    },
                ],
                btnList: [
                    //   {
                    //       type: "primary",
                    //       text: "查询",
                    //       fn: "primary",
                    //       auth: ["organ:list"],
                    //   },
                    // {
                    //     type: "primary",
                    //     text: "添加",
                    //     fn: "submit",
                    //     auth: ["organ:add"],
                    // },
                ],
                btnFormType: true,
            },
            loadingTable: false,
            table_config: {
                thead: [
                    {
                        label: "日期",
                        prop: "attendanceDate",
                        align: "center",
                    },
                    {
                        label: "星期",
                        prop: "week",
                        type: "slot",
                        slotName: "week",
                        align: "center",
                    },
                    {
                        label: "出勤状态",
                        prop: "status",
                        type: "slot",
                        slotName: "status",
                        align: "center",
                    },
                ],
                check: false,
                height: "",
                rowkey: "id",
            },
            table_data: [
                {
                    date: "2020-01-01",
                    weak: "星期1",
                    status: "出勤",
                },
                {
                    date: "2020-01-02",
                    weak: "星期2",
                    status: "出勤",
                }
            ],
            studentInfo:{
                studentId:"",
                studentName:""
            },
        };
    },
    mounted() {
        this.$eventDispatch("setGlobalPageType", "detail");
        this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 100
        this.setDefaultTime();
        if(this.$route.query.studentId){
            this.studentInfo.studentId = this.$route.query.studentId
            this.studentInfo.studentName = this.$route.query.studentName
        }
        this.getList()
    },
    activated() {
        this.$eventDispatch("setGlobalPageType", "detail");
    },
    methods: {
        clickBtn() {},
        changeBtnFormType() {},
        dataChange(data) {
            console.log(data, "筛选条件");
            data.forEach(item => {
                if(item.key == "dateArr"){
                    console.log(item.value, "时间");
                    this.getList()
                }
            });
        },
        back() {
            console.log(this.$route, "路由信息");
            this.$eventDispatch("setBreadcrumbs", this.$route);
            this.$router.push({
                name: "SummaryStudentStatus",
            });
            this.$eventDispatch("setGlobalPageType", "list");
            // this.$router.go(-1)
        },
        setDefaultTime() {
            let startDate = this.$moment().subtract(7, 'days').format("YYYY-MM-DD")
            let currentDate = this.$moment().subtract(1, 'days').format("YYYY-MM-DD");
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [startDate, currentDate];
                }
            });
        },
        async getList(){
            let dateObj = this.formData.data.filter(item => item.key === "dateArr")[0]
            let param = {
                studentId:this.studentInfo.studentId,
                startDate:dateObj.value[0],
                endDate:dateObj.value[1]
            }
            this.loadingTable = true
            await this._fet('/school/schoolStudentAttendance/historyAttendance',param).then((res) => {
                if(res.data.code === "200") {
                    this.table_data = res.data.data
                }
            })

            this.loadingTable = false

           
        },
        // 根据日期获取星期几
        getWeek(date) {
            let week = new Date(date).getDay();
            let weekArr = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            return weekArr[week];
        },
        // 根据标识获取出勤状态
        getAttendancesStatusBycode(code) {
            let stausArr = [
                {
                    code: "3",
                    name: "未到",
                    type: "danger"
                },
                {
                    code: "1",
                    name: "到校",
                    type: "success"
                },
                {
                    code: "2",
                    name: "请假",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link.el-link--warning {
            color:#c600fb
        } 
</style>
